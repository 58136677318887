.gameScreen {
  &__wrapper {
    max-width: 646px;
  }
}

.animation {
  overflow: hidden;
  border-radius: 16px;

  .notification-common-styles {
    font-size: 13px;
  }

    &__wrapper {
    scale: 1.35;
    top: 30px;

    &--small {
      scale: 1.8;
      top: 50px;

      .animation__real-game-btn {
        top: 0;
        right: 23px;
        scale: 0.5;
      }
    }
  }

  &__real-game-btn {
    right: 30px;
    top: 5px;
    scale: 0.75;
  }

  &__fade-number {
    font-size: 6px;
  }

  &__multiplier {
    font-size: 10px;

    &--crashed {
      &--result {
        font-size: 8px;
      }

      &--odd {
        font-size: 12px;
      }
    }

    &__disabled {
      font-size: 40px;

      &--title {
        font-size: 12px;
      }
    }
  }

  &__disabled-betting-timer {
    font-size: 40px;
  }

  &__disabled-betting-timer-title {
    font-size: 8px;
  }

  .single-star {
    transform: scale(0.4);
  }

}

.game {
  &__wrapper {
    flex-direction: row;
    padding: 10px;
    gap: 8px;
  }
}

.control {
  &__wrapper {
    gap: 8px;
  }

  &__controlItems {
    &__wrapper {
      gap: 8px;
    }
  }

  .controlItem {
    &__wrapper {
      flex-direction: column;
      padding: 16px 15px;
    }

    &__left {
      border: none;
      padding: 0;
      flex-direction: unset;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 5px 0;

      &__amount {
        &--btn {
          width: 23px;
          height: 23px;
        }
      }

      &--slider {
        width: 100%;
      }

      &--btn {
        max-width: 124px;

        &--item {
          width: 57px;
          padding: 5px 0;
          font-size: 11px;
          scale: 1;
        }
      }

      &--autoGame {
        flex-direction: column;
        justify-content: center;
        order: unset;

        &--label {
          font-size: 10px;
        }
      }
    }

    &__right {
      flex-direction: row;
      border: none;

      &__bet {
        padding: 0;
        margin-right: 10px;

        &--btn {
          width: 73px;
          height: 73px;

          &--text, &--cashOut-amount {
            font-size: 9px;
          }
        }
      }

      &__cashOut {
        padding: 0;
        align-items: center;

        &--amount {
          label {
            font-size: 10px;
          }

          .controlItem__left__amount {
            &--btn {
              width: 20px;
              height: 20px;
            }

            &--input {
              font-size: 10px;
            }
            &--input::placeholder {
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  &__add {
    max-width: 98px;
  }
}


.controlItem {
  @media (max-height: 641px) {
    &:not(:only-child) {

      &.controlItem__wrapper {
        padding: 16px 8px;
        width: 202px;
      }

      .controlItem__right {
        gap: unset;
        .controlItem__left__amount--input::placeholder{
          font-size: 7px;
        }
      }

      .controlItem__left {
        gap: 5px 0;

        &__amount {
          order: 1;
          width: auto;
        }

        &--autoGame {
          order: 4;
          justify-content: center;
        }

        &--slider {
          order: 2;
        }

        &--btn {
          order: 3;
        }
      }
    }
  }
}


