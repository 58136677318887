.gameScreen {
  &__wrapper {
    max-width: 605px;
  }
}

.header {
  &__wrapper {
    padding: 13px 15px;
  }

  &__button {
    flex-wrap: wrap;

    &-text {
      text-wrap: nowrap;
    }
  }

  &__logo {
    background-position: center;
  }

  &__user {
    width: 100%;
    height: 100%;
    gap: unset;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;

    &__wrapper {
      gap: 5px;
    }

    &__id, &__balance {
      width: 100%;
    }
  }
}

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px 0;

  &__game {
    width: 100%;
    order: 1;
  }

  &__bets {
    order: 2;
    max-width: 296px;
  }

  &__history {
    order: 3;
    max-width: 296px;
  }
}

.table {
  .sub-table {
    .table__body {
      height: calc(100% - 77px);
    }
  }
}



