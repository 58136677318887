.animation {
  width: 100%;
  user-select: none;
  position: relative;

  &.pause-animations * {
    animation-play-state: paused !important;
  }

  .opacity-none {
    opacity: 0 !important;
  }

  &__wrapper {
    width: 100%;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    background-color: #002340FF;
    aspect-ratio: 573 / 364;
  }

  &__background-image {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;

    &.animate {
      animation: moveBackground 12s linear forwards;

    }

    &.stop-animate {
      animation: unset;
    }
  }

  &__clouds-img {
    width: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: clouds-movingX 5s linear forwards;
  }

  &__balloon-img {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  &__real-game-btn {
    position: absolute;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    font-size: 11px;
    top: 10px;
    right: 10px;
    z-index: 3;
    border: none;
    color: white;
    mix-blend-mode: normal;
    cursor: pointer;

    &--text {
      width: 100%;
      text-align: center;
    }

    &--big {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: #3080ca;
      box-shadow: inset 0px -2px 3px 1px hsla(0, 0%, 100%, 0.5);
      border-radius: 20px;
      transition: transform .5s, -webkit-transform .5s;
    }

    &:hover{
      .animation__real-game-btn--big{
        transform: scale(1.1);
        transition: 0.5s;
      }
    }
  }

  &__disabled-betting-timer {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -55%);
    color: lightgrey;
    font-size: 80px;
    font-family: Ubuntu;
    z-index: 3;
  }

  &__disabled-betting-timer-title {
    position: absolute;
    top: 35%;
    left: 50%;
    font-size: 24px;
    color: lightgrey;
    transform: translate(-50%, -35%);
  }

  &__fade-number {
    position: absolute;
    z-index: 2;
    top: 18%;
    left: 50%;
    font-family: "Ubuntu";
    color: #0d152e;
    transform: translate(-50%, -18%);
    transform-origin: left;
    align-items: center;
    animation: fade 1s linear infinite;
  }

  &__stars {
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: starTop 8s linear forwards;
    animation-delay: 3s;
    opacity: 0;
    container-type: size;

    .single-star {
      --size: 2px;
      width: var(--size);
      height: var(--size);
      background: white;
      border-radius: 50%;
      position: absolute;
    }

    @for $i from 1 through 60 {
      .single-star:nth-child(#{$i}) {
        --size: #{random(2) * 1}px;
        top: -3px;
        left: random() * 100%;
        animation: starFall 8s linear infinite;
        animation-delay: -#{random(50000)}ms;
      }
    }

    .single-star:nth-child(6n) {
      filter: blur(1px);
    }
  }

  &__multiplier {
    position: absolute;
    z-index: 5;
    color: white;
    font-size: 30px;
    top: 65%;
    left: 62%;
    transform: translate(-62%, -65%);
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.9);

    &--crashed {
      top: 50%;
      left: 50%;
      color: black;
      font-family: Ubuntu;
      text-align: center;
      transform: translate(-50%, -50%);
      text-shadow: none;

      &--result, &--odd {
        font-size: 25px;
      }
    }

    &__disabled {
      position: absolute;
      z-index: 5;
      color: white;
      top: 55%;
      left: 50%;
      font-size: 80px;
      font-family: Ubuntu;
      transform: translate(-50%, -55%);

      &--title {
        top: 35%;
        left: 50%;
        font-size: 25px;
        position: absolute;
        font-family: Ubuntu;
        transform: translate(-50%, -35%);
        color: lightgrey;
      }

      &--crashed {
        position: absolute;
        z-index: 5;
        top: 55%;
        left: 50%;
        font-size: 80px;
        font-family: Ubuntu;
        transform: translate(-50%, -55%);
        color: red;
      }
    }
  }

}

@keyframes starTop {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }

}

@keyframes starFall {
  100% {
    transform: translateY(100cqh) scale(0.4);
  }
}

@keyframes moveBackground {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }

}

@keyframes clouds-movingX {
  0% {
    top: -200%;
  }
  100% {
    top: 200%;
  }
}

@keyframes fade {
  0% {
    scale: 3.2;
    opacity: 0.2;
  }
  25% {
    scale: 3.6;
    opacity: 0.7;
  }
  50% {
    scale: 4;
    opacity: 1;
  }
  75% {
    scale: 3.6;
    opacity: 0.7;
  }
  100% {
    scale: 3.2;
    opacity: 0.2;
  }
}

//@keyframes starAnimation {
//  from {
//    top: -100%;
//  }
//  to {
//    top: 100%;
//  }
//}
//
//@keyframes shining {
//  0% {
//    opacity: 1;
//  }
//  50% {
//    opacity: 0.3;
//  }
//  100% {
//    opacity: 1;
//  }
//}
