.header {
  border-radius: 16px;
  background: rgba(22, 69, 77, 0.75);

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }

  &__button {
    border-radius: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 5px;

    &-item {
      border-radius: 20px;
      background: #004E6B;
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      max-height: 40px;
      transition: 0.5s;
      cursor: pointer;

      &:nth-child(1) {
        span {
          background-color: #7ED321;
        }
      }

      &:nth-child(2) {
        span {
          background-color: #D8D8D8;
        }
      }

      &:hover {
        background: #00394e;

        span {
          transform: scale(1.2);
        }
      }
    }

    &-icon {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      transition: 0.5s;
    }

    &-text {
      color: #FFFFFF;
      font-size: 11px;
    }
  }

  &__center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &--logo {
      background: url("/../public/img/common/avatars/header-logo.gif");
      width: 100%;
      height: 48px;
      max-width: 167px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__user {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 120px;

    &__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
    }

    &__avatar {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }

    &__tournament {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #004E6B;
      border-radius: 50%;
      transition: 0.5s;
      cursor: pointer;
      >img {
        width: 50%;
      }
      &:hover {
        transform: scale(1.1);
      }
    }

    &__id, &__balance {
      font-size: 11px;
      color: #FFFFFF;
      opacity: 0.5;
      width: 0;
      white-space: nowrap;
    }

    &__nickname {
      color: #EEEEEE;
      font-size: 13px;
    }
  }

  &__mobile {
    display: none;
  }
}