.notification-common-styles {
  background: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  position: absolute;
  border-radius: 5px;
  padding: 10px;
  overflow-wrap: break-word;
  max-width: 80%;
  left: 50%;
  top: 20px;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.notification-visible {
  opacity: 0.88;
  transition: opacity 800ms ease-in-out;
}

.notification-hidden {
  opacity: 0;
}

.notification-failed {
  color: #b70404;
}

.notification-success {
  color: green;
}