.game {
  &__wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(22, 69, 77, 0.75);
    padding: 16px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
  }

  .mobile {
    &__history {

      background-color: rgba(0, 38, 53, 0.95);
      border-radius: 14px;
      padding: 0 5px;

      &__wrapper {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        scrollbar-width: none;
        gap: 5px;
        padding: 6px 5px;
        border-radius: 14px;
      }

      &--odd {
        font-size: 14px;
        color: #ffffff;
        background-color: #004e6b;
        border-radius: 25px;
        padding: 5px 10px;
      }
    }
  }
}