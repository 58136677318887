.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;

  &__content {
    height: 100%;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #28304e;
    opacity: 0.4;
  }


  &--opened {
    z-index: 30;
    display: flex;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 32px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    //-ms-overflow-style: none;
    //scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__wrapper {
    max-width: 812px;
    margin: auto;
    min-width: 18.75rem;
    padding: 32px 40px;
    border-radius: 22px;
    background-color: #101526;
    position: relative;
    height: auto;
    max-height: 90%;
  }

  &__title {
    font-size: 30px;
    color: #FFFFFF;
    margin-top: 0;
  }

  &__title--wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__paragraph {
    font-size: 14px;
    color: #9fa1a8;
    line-height: 24px;
  }

  &__link {
    color: #FFFFFF;
    text-decoration: underline;

    &:hover {
      color: #FFFFFF;
      text-decoration: none;
    }
  }

  &__subtitle {
    font-size: 20px;
    color: #FFFFFF;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    color: #9fa1a8;
    font-size: 14px;
    gap: 16px;

    li {
      position: relative;
      padding-right: 40px;
      padding-left: 11px;
    }

    li:before {
      content: "";
      position: absolute;
      top: 9px;
      left: 0;
      width: 4px;
      height: 4px;
      background-color: #295fc6;
      border-radius: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &__back {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #004e6b;
      border-radius: 16px;
      padding: 7px 8px;
      gap: 4px;

      &--icon {
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-image: url("/../public/img/common/icons/mobile-menu-back.webp");
        background-size: cover;
      }

      &--text {
        font-size: 14px;
        color: #ffffff;
      }
    }
  }


}

.honesty {
  &__game-result {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #50c284;
    padding: 6px 9px;
    gap: 10px;
    color: #ffffff;
    font-size: 14px;

    &--odd {
      color: #50c284;
      font-size: 20px;
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
  }

  &__container-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__info-field {
    position: relative;
    display: flex;
    padding: 10px 50px 8px 10px;
    color: #ffffff;
    background: #1c2439;
    border: 1px solid #3c4150;
    border-radius: 4px;
    word-break: break-all;
    font-size: 14px;

    &--btn {
      position: absolute;
      width: 12px;
      height: 12px;
      border: 1px solid #ffffff;
      border-radius: 2px;
      top: 12px;
      right: 10px;
      cursor: pointer;
      font-size: 14px;

      &::before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border: 1px solid #ffffff;
        border-radius: 2px;
        top: 3px;
        right: 3px;
        background-color: #1c2439;
      }
    }

    &--btn-green {
      border-color: #50c284;

      &::before {
        border-color: #50c284;
      }
    }
  }

  &__icon {
    display: inline-block;
    width: 22px;
    height: 26px;
    margin-right: 8px;
    flex-shrink: 0;
    background-size: contain;
    &--hash {
      background-image: url("/../public/img/common/icons/icon-hash.webp");
    }

    &--formula {
      width: 23px;
      height: 23px;
      background-image: url("/../public/img/common/icons/icon-formula.webp");
    }

    &--salt {
      background-image: url("/../public/img/common/icons/icon-salt.webp");
    }
  }
}

.promo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  &__image {
    position: relative;
    top: 0;
    img {
      width: 100%;
      border-radius: 16px 16px 0 0;
    }
    &:after{
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 17%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(28, 28, 28, 0) 3%, #1a1f31 100%);
    }
  }

  &__content {
    overflow-y: auto;
    overflow-x: hidden;
    h1{
      color: #fee212;
    }
    h6{
      color: #fee212;
      font-size: 1rem;
    }
    p{
      color: #FFFFFF;
      line-height: 24px ;
      font: 400 14px "Ubuntu", sans-serif;
      letter-spacing: .4px;
    }
  }
}
