.dropdown {
  position: relative;

  &__icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #004E6B;
    border-radius: 50%;
    transition: 0.5s;
    cursor: pointer;
    background-image: url("/../public/img/common/icons/icon-settings.webp");
    background-size: 46%;
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      transform: scale(1.1);
    }
    &--settings {
      &-active {
        background-color: #101526;
      }
    }
  }

  &__list {
    min-width: 155px;
    position: absolute;
    background-color: #101526;
    color: #ffffff;
    top: 40px;
    right: -8px;
    border-radius: 5px;
    z-index: 3;

    &--item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 12px;
      font-size: 12px;
      border-bottom: 1px solid #1a1f33;

      &:last-child {
        border: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: -8px;
      right: 19px;
      border: 4px solid transparent;
      border-bottom: 4px solid #101526;
    }
  }
}