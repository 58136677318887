@import "styles/reset";

#root{
    height: 100vh;
}
.app {
    width: 100%;
    height: 100%;
}
.app__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-image: url("../public/img/common/background/main-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    overflow-y: auto;
    overflow-x: hidden;

*::-webkit-scrollbar {
    width: 4px; /* for vertical scrollbars */
    height: 4px; /* for horizontal scrollbars */
    border-radius: 3px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border-radius: .3em;
}
}

@import "styles/desktop/index";

@media (min-width: 641px) and (max-width: 1280px) {
    @import "styles/tablet/index";
}

@media  (max-width: 640px) {
    @import "styles/mobile/index";
}

@media only screen and (orientation: landscape) and (max-height: 640px) and (min-width: 641px) and (max-width: 1280px) {
    @import "styles/landscape/index";
}