.global-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(0, 113, 150, 1) 0%, rgba(0, 63, 92, 1) 100%, rgba(0, 212, 255, 1) 100%);
  opacity: 1;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  visibility: visible;

  &__invisible {
    opacity: 0;
    z-index: 0;
    visibility: hidden;
    transition: opacity 2s ease, z-index 2s ease , visibility 2s ease;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }

  &__img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__error-msg {
    color: #ffffff;
  }
}

.loader {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    width: 140px;
    height: 140px;
  }

  &__image {
    background-image: url("/../public/img/common/loaders/loader.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    animation: rotate 6s linear infinite;;

    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: linear-gradient(180deg, #264f9d, #3f75dc .01%, #14429b);
      border-radius: 50%;
      transform: translateX(-50%);
      left: 50%;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0)
    }
    100% {
      transform: rotate(1turn);
    }
  }
}

.liner-loader, .liner-loader:before, .liner-loader:after {
  border-radius: 50%;
  margin-bottom: 20px;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.liner-loader {
  color: #FFF;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.liner-loader:before,
.liner-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.liner-loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.liner-loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
  40% { box-shadow: 0 2.5em 0 0 }
}