.table {
  &__wrapper {
    background-color: rgba(22, 69, 77, 0.75);
    padding: 16px 10px;
    border-radius: 24px;
    width: 100%;
    height: 100%;
  }

  .sub-table {
    margin-top: 15px;
    height: 510px;
    .table__container,.table__content{
      height: 100%;
    }

    .table__body{
      height: calc(100% - 45px);
    }
  }

  &__select {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 4px;
    border-radius: 20px;
    background: rgba(11, 43, 63, 0.90);

    &__item {
      width: 50%;
      padding: 9px 9px;
      color: #FFFFFF;
      font-size: 9px;
      text-align: center;
      position: relative;
      z-index: 1;
      cursor: pointer;
    }
  }

  .flex-grow{
    flex-grow: 1;
  }

  @for $i from 1 through 3 {
    &__select--#{$i} {
      .toggle {
        width: calc((100% / #{$i}) - 3px);
      }
    }
    @for $j from 1 through $i {
      .active-#{$i} {
        transform: translateX(100% * $j - 100%);
      }
    }
  }

  .active {
    background: linear-gradient(180deg, #264f9d, #295fc6 0, #264f9d);
    border-radius: 24px;
    transition: 0.5s;
  }

  &__content {
    position: relative;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    height: 40px;
    line-height: 40px;
    margin-left: 5px;

    .title {
      width: 100%;
      color: #3AA3CA;
      font-size: 12px;
      text-align: center;
    }
  }

  &__body {
    height: 510px;
    overflow-y: auto;

    &--wrapper {
      display: flex;
      flex-direction: column;
      padding-right: 4px;
      margin-left: 4px;
      margin-top: 1px;
    }

    &--row {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #CCCCCC;
      background-color: #18344D;
      line-height: 40px;
      position: relative;

      &-tournament {
        p:nth-child(1) {
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          font-weight: bold;
        }
        .item--win{
          color: #50c284;
        }

        &--selected {
          position: sticky;
          z-index: 1;
          bottom: 0;
          top: 0;
          background-color: #004E6B !important;
          border: 1px solid #4F9D26;
          &:nth-child(2) {
            color: #4F9D26;
          }
        }

        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          p:nth-child(1) {
            border-radius: 50%;
            color: #000000;
            line-height: 16px;
          }
        }

        &:nth-child(1) {
          p:nth-child(1) {
            background-color: #f7c25b;
          }
        }

        &:nth-child(2) {
          p:nth-child(1) {
            background-color: #EEE;
          }
        }

        &:nth-child(3) {
          p:nth-child(1) {
            background-color: #E48C5A;
          }
        }
      }


      &-betHistory-green {
        outline: 1px solid #50c284;
        z-index: 1;

        .item--odd, .item--win {
          color: #50c284;
        }
      }

      &-roundBets-green {
        .item--odd, .item--win {
          color: #50c284;
        }
      }

      &-roundBets-red {
        .item--odd {
          color: #cd3228;
        }
      }

      .item--tournament {
        display: flex;
        align-items: center;
        justify-content: space-around;
        &:nth-child(2) {
          flex-grow: 1;
        }
      }


      &-highestMultipliersRounds:nth-child(1), &-highestMultipliersRounds:nth-child(2), &-highestMultipliersRounds:nth-child(3) {
        border: 1px solid;

        &:before {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          color: #000;
          position: absolute;
          line-height: 12px;
          font-size: 10px;
          top: 50%;
          text-align: center;
          transform: translateY(-50%);
          left: -5px;
          z-index: 1;
        }
      }

      &-highestMultipliersRounds:nth-child(1) {
        border-color: #f7c25b;

        &:before {
          content: "1";
          background-color: #f7c25b;
        }
      }

      &-highestMultipliersRounds:nth-child(2) {
        border-color: #eee;

        &:before {
          content: "2";
          background-color: #eee;
        }
      }

      &-highestMultipliersRounds:nth-child(3) {
        border-color: #e48c5a;

        &:before {
          content: "3";
          background-color: #e48c5a;
        }
      }

      .item--date:hover {
        &:before {
          content: attr(data-full_date);
          position: absolute;
          background: #3080ca;
          border-radius: 0 4px 4px 0;
          left: 0;
          padding: 0 8px;
        }
      }

      .item--honestyIcon {
        text-align: center;

        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }

      &:nth-child(odd) {
        background-color: #1F4363;
      }

      &--item {
        width: 100%;
        text-align: center;
        &:nth-child(1) {
          padding-left: 8px;
        }
      }
    }
  }

  .toggle {
    height: calc(100% - 8px);
    background: linear-gradient(180deg, #004E6B, #004E6B 0, #004E6B);
    border-radius: 24px;
    position: absolute;
    transition: 0.3s;
  }

  &__container {
    position: relative;
  }
}
