.control {
  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  &__add {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px dashed rgba(90, 111, 203, 0.5);
    border-radius: 16px;
    max-width: 144px;
    gap: 5px;
    cursor: pointer;

    &--btn {
      width: 66px;
      height: 66px;
      background: #0d152e;
      border-radius: 50%;
      position: relative;
      transition: 0.5s;

      &:after, &:before {
        content: "";
        position: absolute;
        background-color: #fff;
        width: 1px;
        height: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        width: 50%;
        height: 1px;
      }
    }

    &--text {
      font-size: 12px;
      text-align: center;
      color: #9fa1a8;
      transition: 0.5s;
    }

    &:hover {
      color: orange;

      .control__add--btn {
        background: #3080ca;
      }

      .control__add--text {
        color: #3080ca;

      }
    }
  }
}

.control__controlItems__wrapper {
  display: flex;
  gap: 12px;
}
.controller--disabled{
  opacity: 0.5;
  pointer-events: none;
}

.controlItem {

  &__wrapper {
    display: flex;
    background-color: #004E6B;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .5), inset 0 -4px 3px 0.418415px rgba(0, 0, 0, .5);
    border: 2px;
    border-radius: 16px;
    position: relative;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 2px solid #04597B;
    padding: 16px 22px 16px 16px;

    &__amount {
      display: flex;

      &--margin {
        background: linear-gradient(180deg, #14354C 0%, rgba(19, 46, 65, 0.00) 100%), #002635;
        border-radius: 50px;
        position: relative;
        display: flex;
        justify-content: space-around;
        padding: 7px;

        .plus:after {
          content: "";
          position: absolute;
          width: 1px;
          height: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #FFFFFF;
        }
      }

      &--btn {
        position: relative;
        height: 30px;
        width: 30px;
        background-color: #12B679;
        border: none;
        border-radius: 50%;
        transition: scale 0.5s;
        flex: 0 0 auto;

        &:hover {
          scale: 1.2;
        }

        &:disabled {
          background-color: transparent;
          border: 1px solid #898fa6;

          &:hover {
            scale: none;
          }
        }

        &:before {
          content: "";
          position: absolute;
          background-color: #FFFFFF;
          width: 50%;
          height: 1px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &--input {
        border: none;
        background: transparent;
        color: #FFFFFF;
        text-align: center;
        border-bottom: 1px solid hsla(0, 0%, 100%, .2);
        width: 100%;
      }

      &--double {
        width: 100px;
        background: linear-gradient(178.43deg, #b4ec51 0.89%, #429321 98.3%);
        box-shadow: inset 0 -2px 3px hsla(0, 0%, 100%, .5);
        border-radius: 50px;
        border: none;
        margin-left: -50px;
        padding-left: 42px;
        color: #FFFFFF;
        font-size: 14px;

        &:disabled {
          background: linear-gradient(165deg, #b0b9de 8.01%, #898fa6 86%)
        }
      }
    }

    &--slider {
      input {
        -webkit-appearance: none; /* Override default CSS styles */
        height: 7px;
        appearance: none;
        background: #00384c;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.38) inset;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
        border-radius: 16px;
        border: 0.837px solid #242D52;
        width: 100%;
      }

      input::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 19px;
        height: 19px;
        background: linear-gradient(178.43deg, #b4ec51 0.89%, #429321 98.3%);
        cursor: pointer;
        border-radius: 50%;
      }

      input::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background: linear-gradient(178.43deg, #b4ec51 0.89%, #429321 98.3%);
        cursor: pointer;
      }
    }

    &--btn {
      display: flex;
      flex-wrap: wrap;
      border: none;
      justify-content: space-between;

      &--item {
        width: 100px;
        margin-bottom: 8px;
        padding: 7px 0;
        font-size: 14px;
        outline: none;
        color: #FFFFFF;
        background: #3080CA;
        scale: 1;
        box-shadow: 0px -2px 3px 1px rgba(255, 255, 255, 0.50) inset;
        transition: scale 0.2s linear;
        border-radius: 12px;
        border: 1px solid #003D56;
        text-wrap: nowrap;

        &:hover {
          scale: 1.1;
        }

        &:active {
          box-shadow: none;
        }
      }
    }

    &--autoGame {
      display: flex;
      align-items: center;
      gap: 5px;

      &--label {
        color: #3aa3ca;
        font-size: 12px;
        text-align: center;
      }
    }

  }

  &__right {
    border-left: 2px solid #003D56;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__bet {
      padding: 20px;

      &--btn {
        width: 117px;
        height: 117px;
        position: relative;
        background: linear-gradient(213.52deg, #ff3d54 4.32%, #ff6276 93.09%);
        border-radius: 50%;
        border: none;

        color: #FFFFFF;
        box-shadow: 0 5px 0 0 rgba(105, 23, 18, 1);

        &--text {
          padding: 0 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-weight: bold;
        }

        &--cashOut-amount {
          background: hsla(0, 0%, 100%, .5);
          border-radius: 100px;
          padding: 2px 5px;
        }

        &:hover {
          background: linear-gradient(219.29deg, #ff9ba7 14.2%, #ff5d70 34.76%, #ff233d 85.47%);
        }

        &:disabled {
          background: linear-gradient(165.71deg, #b0b9de 8.01%, #898fa6 85.9%);
          box-shadow: unset;
        }
      }

      .green {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: linear-gradient(180deg, #6cd99e, #21b866);
        box-shadow: 0 5px 0 0 #04793a;

        &:hover {
          background-image: linear-gradient(180deg, #8be2b3, #63b98b);
          background-color: #7ed1a2;
        }
      }

      .orange {
        background: #FF6700;
        box-shadow: 0 5px 0 0 #954a17;

        &:hover {
          background-image: linear-gradient(180deg, #ff6a00, #ffa767);
          background-color: #954a17;
        }
      }
    }

    &__cashOut {
      display: flex;
      flex-direction: row;
      gap: 5px;
      padding: 0 20px 16px 5px;
      justify-content: space-between;

      &--amount {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2px;

        .controlItem__left__amount {

          &--margin {
            padding: 2px;
          }

          &--input {
            font-size: 12px;

            &::placeholder {
              font-size: 12px;
            }
          }

          &--btn {
            height: 20px;
            width: 20px;
            scale: none;

            &:disabled {
              background-color: transparent;
              border: 1px solid #898fa6;
            }
          }

        }

        &--disabled {
          pointer-events: none;
          opacity: 0.5;

          .controlItem__left__amount--margin {
            background: inherit;
          }

          .controlItem__left__amount--btn {
            background-color: initial;
            border: 1px solid gray;
          }
        }

        label {
          color: #3aa3ca;
          font-size: 12px;
        }

      }
    }

    .auto-cashOut-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__close {
    width: 19px;
    height: 19px;
    position: absolute;
    right: -3px;
    top: -3px;
    background-image: url("/../public/img/common/icons/icon-close.webp");
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: transform .3s ease-in-out;
    border-radius: 50%;

    &:hover {
      transform: rotate(-90deg) scale(1.27);
    }
  }

  @media (min-width: 641px) {
    &:not(:only-child) {

      &.controlItem__wrapper {
        flex-direction: column;
        padding: 16px 16px;
      }

      .controlItem__right {
        border: none;
        padding: 0;
        flex-direction: row;
        gap: 20px;

        &__bet {
          padding: 0;

          &--btn {
            width: 73px;
            height: 73px;
            &--text, &--cashOut-amount{
              font-size: 9px;
            }
          }
        }

        &__cashOut {
          padding: 0;
          align-items: center;

          &--amount {
            gap: 5px;
            .controlItem__left__amount{
              width: 100%;
            }
          }
        }
      }

      .controlItem__left {
        border: none;
        padding: 0;
        flex-direction: unset;
        flex-wrap: wrap;
        gap: 10px 0;

        &__amount {
          order: 1;
          width: 70%;

          &--btn {
            width: 23px;
            height: 23px;
          }
        }

        &--autoGame {
          order: 2;
          flex-direction: column;
          justify-content: space-between;
        }

        &--slider {
          order: 3;
          width: 100%;
        }

        &--btn {
          order: 4;
          width: 100%;

          &--item {
            width: 57px;
            padding: 5px 0;
            word-wrap: break-word;
            font-size: 11px;
          }
        }
      }

    }
  }
}
