.moving-z {
  position: absolute;
  z-index: 1;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: moving-z 10s linear forwards;
}

.moving-z-left {
  position: absolute;
  z-index: 1;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: moving-z-left 7s linear forwards;
}

.moving-y {
  position: absolute;
  z-index: 1;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: moving-y 12s linear forwards;
}

@keyframes moving-y {
  0% {
    top: -20%;
    transform: translate(-50%, -50%) rotate(0deg)
  }
  100% {
    top: 110%;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


@keyframes moving-z-left {
  0% {
    top: -20%;
    left: 110%;
    transform: translate(-50%, -50%) rotate(240deg)
  }
  100% {
    top: 120%;
    left: -10%;
    transform: translate(-50%, -50%) rotate(0deg)
  }
}

@keyframes moving-z {
  0% {
    top: -20%;
    left: -10%;
    transform: translate(-50%, -50%) rotate(0deg)
  }
  100% {
    top: 120%;
    left: 110%;
    transform: translate(-50%, -50%) rotate(120deg)
  }
}