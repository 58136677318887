.gameScreen {
  &__wrapper {
    max-width: 355px;
    gap: 8px;
  }
}

.main {
  &__bets, &__history {
    display: none
  }
}

.game {
  &__wrapper {
    background-color: unset;
    padding: unset;
  }
}

.control {
  &__wrapper {
    flex-direction: column;
  }

  &__add {

    max-width: unset;
    padding: 47.6px 37px;
  }
}

.control__controlItems__wrapper {
  flex-direction: column;
}

.controlItem {

  &__wrapper {
    display: flex;
    background-color: #004E6B;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .5), inset 0 -4px 3px 0.418415px rgba(0, 0, 0, .5);
    border: 2px;
    border-radius: 16px;
  }

  &__left {
    padding: 9px 8px;

    &__amount {
      &--margin {
        padding: 4px;
      }

      &--btn {
        width: 23px;
        height: 23px;
      }
    }

    &--btn {

      &--item {
        width: 85px;

        &:hover {
          scale: 1;
        }
      }
    }

  }

  &__right {

    align-items: center;

    &__cashOut {
      padding: 0 5px 16px 5px;

      .controlItem__left__amount--input::placeholder {
        font-size: 10px;
      }
    }

    &__bet {

      &--btn {
        width: 88px;
        height: 88px;

        &--text, &--cashOut-amount {
          font-size: 9px;
        }

        &:hover {
          background: linear-gradient(219.29deg, #ff9ba7 14.2%, #ff5d70 34.76%, #ff233d 85.47%);
        }
      }
    }

  }

}

.header {
  padding: 12px 12px 11px;

  &__wrapper {

  }

  &__button {
    display: none;
  }

  &__center {
    &--logo {
      height: 27px;
      background-size: contain;
    }
  }

  &__user {
    display: none;
  }

  &__mobile {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &--balance {
      width: 100%;
      justify-content: flex-start;
      font-size: 13px;
      color: #ffffff;
    }

    &--settings {
      height: 30px;
      width: 30px;
      background-image: url("/../public/img/common/icons/icon-mobile-menu.webp");
      background-size: cover;
    }

  }
}

.animation {
  &__fade-number {
    font-size: 12px;
  }

  &__multiplier {

    &--crashed {
      &--result {
        font-size: 10px;
      }

      &--odd {
        font-size: 20px;
      }
    }

    font-size: 20px;

    &__disabled {
      font-size: 40px;

      &--title {
        font-size: 12px;
      }
      &--crashed {
        font-size: 40px;
      }
    }
  }

  &__disabled-betting-timer {
    font-size: 50px;
  }

  &__disabled-betting-timer-title {
    font-size: 12px;
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1f31;
  z-index: 10;

  &__wrapper {
    display: flex;
    justify-content: center;
    padding: 15px 0;
    flex-direction: column;
    height: 100%;
  }

  &__top {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
    color: #ffffff;
  }

  &__close {
    &--btn {
      position: relative;
      display: flex;
      height: 20px;
      justify-content: center;

      &:before, &:after {
        content: '';
        position: absolute;
        height: 20px;
        border: 1px solid #ffffff;
      }

      &:before {
        rotate: 45deg;
      }

      &:after {
        rotate: -45deg;
      }
    }
  }

  &__bottom {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 18px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &--item {
      text-align: center;
      font-size: 16px;
      padding: 3px;
    }
  }
}

.popup {

  &__body {
    height: calc(100% - 50px);

    .loader__wrapper {
      height: calc(100% - 50px);
    }

    .table {
      //&__wrapper {
      //  background-color: unset;
      //  padding: 0;
      //}

      &__select, &__header, &__body--wrapper {
        margin-left: 5px;
      }

      &__content {
        height: calc(100% - 40px);
      }

      &__body {
        height: calc(100vh - 190px);
      }
    }
  }

  &__wrapper {
    background-color: #1a1f31;
    height: 100%;
    max-height: none;
    border-radius: unset;
    padding: 16px 16px;
    margin: 0;

    .controlItem__close {
      display: none;
    }
  }


  &__mobile {
    &__settings {
      color: #ffffff;

      &--item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid hsla(0, 0%, 100%, .05);
        padding: 20px 0;

        &:nth-child(1) {
          padding-top: 0;
        }
      }
    }
  }

  &__title {
    font-size: 25px;
  }
}

.promo {
  margin-top: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 60px);
}

@media (max-width: 374px) {
  .gameScreen {
    &__wrapper {
      max-width: 300px;
    }
  }
  .controlItem {

    &__wrapper {
      display: flex;
      background-color: #004E6B;
      box-shadow: 0 2px 8px rgba(0, 0, 0, .5), inset 0 -4px 3px 0.418415px rgba(0, 0, 0, .5);
      border: 2px;
      border-radius: 16px;
    }

    &__left {
      padding: 9px 8px;

      &__amount {
        &--margin {
          padding: 3px;
        }

        &--btn {
          width: 20px;
          height: 20px;
        }
      }

      &--btn {

        &--item {
          width: 60px;

          &:hover {
            scale: 1;
          }
        }
      }

    }

    &__right {

      align-items: center;

      &__bet {

        &--btn {
          width: 88px;
          height: 88px;

          &:hover {
            background: linear-gradient(219.29deg, #ff9ba7 14.2%, #ff5d70 34.76%, #ff233d 85.47%);
          }
        }
      }

    }

  }
}
