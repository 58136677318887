.switch {
  padding: 2px 3px;
  border-radius: 12px;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    background: linear-gradient(to bottom, #1B7DA4, #04678C);
    border-radius: 50%;
    width: 14px;
    height: 14px;
    transition: all 0.3s ease-in-out;
  }

  &__horizontal {
    width: 40px;
    height: 18px;
    border-radius: 12px;
    position: relative;
    padding: 2px 3px;
    cursor: pointer;

    &:before {
      left: 2px;
    }

    &--enabled {
      background-color: #62c43b;
      box-shadow: inset 0 -2px 3px hsla(0, 0%, 100%, .5);

      &:before {
        background: #FFFFFF;
        left: 23px;
      }

      &:hover {
        &:before {
          transform: translateX(-2px);
        }
      }
    }

    &--disabled {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, .376612);
      background-color: #00384c;

      &:hover {
        &:before {
          transform: translateX(2px);
        }
      }
    }
  }

  &__vertical {
    padding: 3px 2px;
    min-width: 18px;
    height: 40px;

    &:before {
      bottom: 2px;
    }

    &--enabled {
      background-color: #62c43b;
      box-shadow: inset 0 -2px 3px hsla(0, 0%, 100%, .5);

      &:before {
        background: #FFFFFF;
        bottom: 23px;
      }

      &:hover {
        &:before {
          transform: translateY(2px);
        }
      }
    }

    &--disabled {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, .376612);
      background-color: #00384c;

      &:hover {
        &:before {
          transform: translateY(-2px);
        }
      }
    }
  }
}
