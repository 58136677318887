.main {
  width: 100%;
  display: flex;
  gap: 32px;

  &__game {
    width: 100%;
  }

  &__bets, &__history {
    min-width: 296px;
    display: flex;
  }
}