.gameScreen {
  max-width: 1264px;
  padding: 16px 0;

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

}

.gameScreen-not-loaded {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgb(0, 113, 150) 0%, rgb(0, 63, 92) 100%, rgb(0, 212, 255) 100%);
}

.tournament__header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px;
  background-color: rgba(0, 78, 107, 0.85);
  border-radius: 16px;

  &__center {
    padding: 4px;
    align-self: center;
    border-radius: 4px;
    display: flex;
    &__wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__item {
      display: flex;
      font-size: 12px;
      color: #FFFFFF;
      flex-direction: row;

      &--prize-pool, &--points {
        color: #9FDC48;
      }
    }
  }

  &__right {
    width: 25px;
    height: 25px;
    margin-left: 5px;

    &__wrapper {
      width: 100%;
      height: 100%;
      > img {
        width: 100%;
        height: 100%;
      }
    }

  }
}